<template>
  <div>
    <h2>Login</h2>
    <p>
      <UButton @click="onLogin"> Go To Login </UButton>
    </p>
  </div>
</template>

<script setup lang="ts">
const { $oauthClient } = useNuxtApp();

// const baseUrl = "https://shopify.com/52993786026/auth/oauth/authorize";

// async function createCodeVerifier() {
//   const array = new Uint8Array(32);
//   crypto.getRandomValues(array);
//   const randomString = String.fromCharCode.apply(null, Array.from(array));

//   const encoder = new TextEncoder();
//   const data = encoder.encode(codeVerifier);
//   const digest = await crypto.subtle.digest("SHA-256", data);
//   return base64UrlEncode(digest);
// }

// async function createCodeChallenge(codeVerifier) {
//   const encoder = new TextEncoder();
//   const data = encoder.encode(codeVerifier);
//   const digest = await crypto.subtle.digest("SHA-256", data);
//   return base64UrlEncode(digest);
// }

// const sha256 = (buffer) => {}crypto.createHash("sha256").update(buffer).digest();
// const createVerifier = () => base64URLEncode(crypto.randomBytes(32));
// const createChallenge = (verifier) => base64URLEncode(sha256(verifier));

function generateCodeVerifier() {
  const rando = generateRandomCode();
  return base64UrlEncode(rando);
}

async function generateCodeChallenge(codeVerifier) {
  const digestOp = await crypto.subtle.digest(
    { name: "SHA-256" },
    new TextEncoder().encode(codeVerifier)
  );
  const hash = convertBufferToString(digestOp);
  return base64UrlEncode(hash);
}

function generateRandomCode() {
  const array = new Uint8Array(32);
  crypto.getRandomValues(array);
  return String.fromCharCode.apply(null, Array.from(array));
}

function convertBufferToString(hash) {
  const uintArray = new Uint8Array(hash);
  const numberArray = Array.from(uintArray);
  return String.fromCharCode(...numberArray);
}

function generateState() {
  const timestamp = Date.now().toString();
  const randomString = Math.random().toString(36).substring(2);
  return timestamp + randomString;
}

function generateNonce(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let nonce = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    nonce += characters.charAt(randomIndex);
  }

  return nonce;
}

// const CLIENT_ID = "shp_04acd37b-492d-4585-8663-597cf6c735ab";
// const REDIRECT_URI = "https://astronomerchweb.loca.lt/auth/callback";

// const buildLoginUrl = async () => {
//   const baseUrl = `https://shopify.com/52993786026/auth/oauth/authorize`;

//   const authorizationRequestUrl = new URL(baseUrl);
//   authorizationRequestUrl.searchParams.append(
//     "scope",
//     "openid email https://api.customers.com/auth/customer.graphql",
//   );
//   authorizationRequestUrl.searchParams.append("client_id", CLIENT_ID);
//   authorizationRequestUrl.searchParams.append("response_type", "code");
//   authorizationRequestUrl.searchParams.append("redirect_uri", REDIRECT_URI);
//   authorizationRequestUrl.searchParams.append("state", generateState());
//   authorizationRequestUrl.searchParams.append("nonce", generateNonce(10));
//   // Public client

//   const verifier = await generateCodeVerifier();
//   const challenge = await generateCodeChallenge(verifier);
//   //localStorage.setItem("code-verifier", verifier);
//   authorizationRequestUrl.searchParams.append("code_challenge", challenge);
//   authorizationRequestUrl.searchParams.append("code_challenge_method", "S256");
// };

const onLogin = async () => {
  console.log("onLogin");
  // oauthClient.value = await createOauthClient();

  // console.log(oauthClient.value);

  // const isLoggedIn = oauthClient.value.isAuthorized();

  // console.log('isLOggedIn');
  // console.log(isLoggedIn);
  // await oauthClient.value.requestAuthorizationCode();

  const oneTimeOpts = {
    nonce: generateNonce(10),
  };

  await $oauthClient.requestAuthorizationCode(oneTimeOpts);
  // window.location.href = authorizationRequestUrl.toString();
};

onMounted(() => {
  // https://shopify.com/66846032107/auth/lookup?destination_uuid=74522435-d3f9-464f-af2c-3dd4ec1af873&redirect_uri=https%3A%2F%2Fastronomerch.loca.lt%2Fauth%2Fcallback&rid=a63a9265-0e0a-4b76-9896-ec7f26c995c1
});
</script>
